@media(min-width:1199px) {
    .container {
        max-width: 1190px;
    }
   
}
@media(max-width:1199px) {
    .banner-new {
        padding-bottom: 3rem;
    }
    .ads-div .btn-theme{
        width: auto;
        font-size: 14px;
        padding: 10px 20px;
    }
    .ads-offer-span01{
        font-size: 24px;
    }
    .ads-offer-span02{
        font-size: 16px;
    }
    .ads-offer-span03 {
        padding: 5px 6px 5px 6px;
    }
    .ads-offer-bottom {
        font-size: 12px;
        margin-top: 5px;
    }
    .ads-offer{
        padding-left: 20px;
        padding-right: 20px;
      }
      .ads-div img{
        width: 200px;
      }
      .flex-wrap-abt{
        flex-wrap:wrap
    }
    .abt-ex-tools {
        margin-bottom: 5rem;
    }
}
@media(max-width:1024px){
    .slider .slick-dots {
        bottom: -29px;
    }
    .btn-theme {
        padding: 20px 15px 20px 32px;
    }
    .abtwrap3-tophead{
margin-top:2rem;
    }
    .thankyou{
        border:none;
    }
    /* .footer{
        position: absolute;
        bottom: 0;
    } */
    .thankyou{
        height: auto;
    }
    .thankyou-body {
        height: 55%;
    }
    .t-m-list-content, .t-m-list-post {
        font-size: 16px;
    }
    .t-m-heading {
        font-size: 20px;
    }
    .t-m-list-name {
        width: 63%;
    }
    
    .registration-info {
        flex-direction: column;
    }
    .registration-info {
        gap: 27px;
    }
    .banner-new{
        padding-bottom: 3rem;
    }
    .flex-wrap-abt{
        flex-wrap:wrap
    }
    .abtwrap3-mainhead {
        padding: 20px 0px 0px 0px;
    }
    .main-features {
        min-height: 250px;
    }
    .ex-tools {
        font-size: 15px;
        width: 145px;
        padding: 10px 13px;
        padding-top: 75px;
    }
    .aboutwrap-3 {
        padding: 10px;
    }
}
@media(max-width:991px){
    .slider .slick-dots {
        bottom: -38px;
    }
    .parnters-featured {
        gap: 2rem;
    }
    body .slick-prev {
        left: 35%;
    }
    body .slick-next {
        right: 35%;
    }
    .about-head{
        width:100%;
    }
    .thankyou{
        border:none;
    }
    .register-left {
        min-height: auto;
    }
    .tools {
        width: 300px;
    }
    .banner-heading,.banner-two-heading {
        font-size: 40px;
        margin-bottom: 20px;
    }
    .banner-para,.banner-two-para {
        font-size: 22px;
    }
    .banner-list-item,.main-para,.banner-two-list-item,.possible-div .main-para{
        font-size: 16px;
    }
    .flex-column-row{
        flex-direction: column-reverse;
        gap: 30px;
    }
    .banner-list,.banner-two-list{
        width: 100%;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .btn-theme {
        font-size: 18px;
        padding: 15px 20px;
    }
    .about-features {
        gap: 40px;
        padding-bottom: 0;
    }
    .main-head {
        font-size: 33px;
        line-height: 44.37px;
        padding: 30px 0px;
    }
    .feature-digit-large {
        font-size: 26px;
    }
    .feature-digit-small {
        font-size: 18px;
    }
    .about-wrap,.corporate-wrap,.career-mastery, .career-tools-wrapper, .client-says-wrapper,
    .features-wraper, .faq-wraper {
        margin-top: 50px;
    }
    .t-m-list-6,  .t-m-list-4 {
        height: 350px;
    }
    .t-m-list-6:nth-last-child(1) {
        height: auto;
    }
    .career_inner_left, .career_inner_right{
        width: 100%
    }
    .career_inner::before,.career_details::before,.career_inner:last-child::after{
        left: 25px;
    }
    .career_details,.career_inner{
        gap: 50px;
    }
    .career_inner{
        padding-left: 70px;
    }
    .career_head{
        margin-bottom: 20px;
    }
    .career-advisor-desc, .career_head {
        font-size: 20px;
        line-height: 32px;
    }
    .main-head-half {
        width: 100%;
    }
    .client-org-wrap,.career-wrap {
        padding-top: 30px;
    }
    .head-last{
        font-size: 18px;
    }
    .register-left ,.register-right{
        padding: 10px 10px 0px 15px;
    }
    .reg-form {
        padding-top: 50px;
    }
    .reg-head {
        font-size: 22px;
        line-height: 30px;
        padding-top: 20px;
    }
    .reg-form {
        padding-top: 45px;
    }
    .mentor-data {
        padding-bottom: 50px;
    }
    .registration-info {
        gap: 20px;
        padding-top: 30px;
        padding-bottom: 15px;
    }
    .registration-info {
        flex-direction: row;
    }
    .bymentor {
        font-size: 14.5px;
        padding-bottom: 1px;
    }
    .main-head3{
        width:100%;
    }
    .banner-new{
        padding-bottom: 3rem;
    }
    .abt-ex-tools{
        margin-bottom: 4rem;
    }
    .main-features{
        height: auto;
        min-height: auto;
        margin-bottom: 0.8rem;
    }
    .toolimage {
        min-height: 196px;
    }
   .toolname{
    min-height: 125px;
   }
}
@media(max-width: 767px){
    .toolname {
        min-height: unset;
    }
    .toolimage {
        min-height: unset;
    }
    
    .video-player.playing {
        transform: unset;
        
      }
      .videoIcon img{
        width:70px;
        height: 70px;
      }
      .video-player.playing {
        transform: unset;
        transition: unset;
      }
    .main-head-sub{
        font-size: 16px;
        padding-top: 10px;
    }
    .slider .slick-dots {
        bottom: -30px;
    }
    body .slick-prev{
        left:15%;
    }
    body .slick-next{
        right:15%;
    }
    .parnters-featured {
        gap: 2rem;
        margin: 0 auto;
        display: block;
        text-align: center;
    }
    .parnters-featured .partner-img {
        width: 110px;
    }
    .client-bottom-bar-p {
        font-size: 16px;
    }
 
    .card-img img {
        margin: 0px 0px 0px 0px;
        width:50%;
    }
    .main-head-3 {
        font-size: 20px;
        padding: 0px 0px;
    }
    
    .abtwrap3-head3 {
        padding-bottom: 2rem;
    }
    .abtwrap3-mainhead {
        font-size: 20px;
        padding: 20px 0px 0px 0px;
    }
    .abtwrap3-tophead {
        font-size: 14px;
    }
    .ex-tools{
        width:100%;
        min-height: auto;
        padding-top: 54px;
        font-size: 14px;
    }
    .abt-ex-tools {
        margin-top: 4rem;
    }
    .ex-img {
        width: 30%;
    }
    .aboutwrap-3 {
        margin-bottom: 30px;
    }
    .text-sm-center{
        text-align: center;
    }
    .nav-center-sm{
display: flex;
justify-content: center;
align-items: center;
    }
    .thank-img {
        padding-bottom: 1rem;
    }
    .thank-desc {
        padding: 5px 25px;
    }
    .thank-img img{
        width:100px;
    }
    .footer{
       text-align: center;
    }
    .thankyou{
        height: auto;
    }
    .thankyou-body {
        height: 55%;
    }
    .reg-head,.registration-info,.mentoredBy {
        padding-top: 30px;
    }
    .reg-form{
        margin-bottom:2rem;
    }
    /* .registration-info {
        padding-top: 15px;
    } */
    /* .mentoredBy {
        padding-top: 15px;
    } */
    .form-label {
        font-size: 14.5px;
        line-height: 20px;
    }
    .mentor-data {
        padding-bottom: 35px;
    }
    .reg-form {
        padding-top: 20px;
    }
    .back-to{
        display: none;
    }
    .form-space {
        margin-bottom: 1.2rem;
    }
    .reg-ico {
        width: 15px;
        height: 15px;
    }
    .bymentor {
        font-size: 12.5px;
    }
    .reg-data-sub{
        font-size: 12px;
    }
    .mentor-image {
        width: 45px;
        height: 45px;
    }
    .reg-data {
        gap: 5px;
    }
    .mentor-position {
        font-size: 11px;
        font-family: "OpenSans Regular";
    }
    .input-group-text{
        height: 50px;
    }
    .mentor-name {
        font-size: 13.5px;
        padding-bottom: 2px;
        font-family: "OpenSans Medium";
    }
    
    .reg-form .form-control {
        height: 50px;
        font-size: 15px;
    }
    .reg-form select{
        font-size: 15px;
    }
   
    .features-sub {
        width: 100%;
    }
    .featured-div {
        flex-direction: column;
    }
    .main-head {
        font-size: 26px;
        line-height: 1.3;
    }
    .feature-digit-large {
        font-size: 21px;
    }
    .parnters {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        /* flex-direction: column; */
        justify-content: center;
        align-items: center;
    }
    .abt-feauture-img {
        width: 90px;
        height: 90px;
    }
    .banner-heading,.banner-two-heading {
        font-size: 30px;
        margin-bottom: 15px;
    }
    .banner-para,.banner-two-para {
        font-size: 18px;
        font-family: 'OpenSans Regular';
    }
    .banner-list-item,.banner-two-list-item{
        font-size: 14px;
    }
    .banner-list-item img,.banner-two-list-item img{
        width: 24px;
        height: 24px;
    }
    .banner_book_div img{
        width: 24px;
    }
    .flex-column-row {
        gap: 15px;
    }
    .banner_book_div{
        margin-top: 15px;
    }
    .t-m-list-6,  .t-m-list-4 {
        height: auto;
    }
    .t-m-heading,.career_head {
        font-size: 18px;
    }
    .t-m-list-content, .t-m-list-post,.banner_book_div, .main-para, .faq-wraper .accordion-body,.possible-div .main-para {
        font-size: 14px;
    }
    img.t-m-linkedIn {
        width: 25px;
        height: auto;
    }
    .t-m-list-name {
        width: 70%;
    }
    .career_inner::before,.career_details::before{
        left: 25px;
    }
    .career_details,.career_inner{
        gap: 30px;
    }
    .career_inner{
        padding-left: 60px;
    }
    .career_head{
        margin-bottom: 10px;
        line-height: 1.3;
    }
    .career-advisor-desc,.feature-digit-small, .faq-wraper .accordion-button,.tooldesc,.head-last {
        font-size: 16px;
        line-height: 1.3;
    }
    .c-coach-img-title {
        font-size: 22px;
    }
    .career_inner::before{
        font-family: 'OpenSans Regular';
        font-size: 15px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
    .career-btn-div{
        width: 100%;
        margin-top: 0px;
      }
      .tools{
        width: 100%;
      }
      .team-member-div{
        margin-top: 0;
      }
      .parnterimg{
        padding-bottom: 0;
        width: 100%;
      }
      .t-m-list-6:nth-last-child(1){
        margin-bottom: 0;
      }
      .banner,.banner-two{
        padding-top: 50px;
      }
      .more-link,.toolname{
        font-size: 18px;
      }
      .toolimage img{
        width: 80px;
      }
      .faq-wraper .accordion-button {
        padding: 1rem 1.25rem;
        padding-left: 60px;
    }
    .faq-wraper .accordion-body {
        padding-top: 0;
    }
    .toolname{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .features-sub .desc-feature{
        margin-top: 0;
        padding-top: 10px;
    }
    .desc-feature{
        padding-top: 0;
        margin-top: 8px;
    }
    .team-member-div .main-head{
        padding-bottom: 50px;
    }
    .possible-div{
        padding: 15px;
    }
    .faq-wraper {
        margin-bottom: 50px;
    }
    .ads-div .btn-theme{
        font-size: 12px;
        padding: 8px 16px;
    }
    .ads-offer-span01{
        font-size: 18px;
        font-family: 'OpenSans SemiBold';
    }
    .ads-offer-span02{
        font-size: 12px;
        font-family: 'OpenSans SemiBold';
        text-decoration-thickness: 2px;
    }
    .ads-offer-span03 {
        padding: 3px 5px 3px 5px;
        font-size: 10px;
    }
    .ads-offer-bottom {
        font-size: 10px;
        margin-top: 5px;
    }
    .ads-offer{
        padding-left: 10px;
        padding-right: 10px;
      }
      .ads-div img {
        width: 120px;
    }
    .ads-offer-inr{
        gap: 10px;
    }
    .btn-sm {
        font-size: 10px;
        padding: 10px 30px;
    }
}
@media(max-width:576px){
   
    .thank-head{
        font-size: 20px;
      }
      .thank-desc{
        font-size: 13px;
      }
    .about-features .features{
        width: 100%;
    }
    .partner-img{
        width: 100%;
    }
    .parnters{
        flex-direction: column;
    }
    .about-features{
        flex-direction: column;
    }
    .banner_two_connt{
        width: 100%
      }
      .banner-two-list{
        gap: 10px;
      }
      .banner-two-list-item{
        padding: 5px 10px;
      }
      .btn-theme {
        font-size: 16px;
        padding: 10px 15px;
    }
    .btn-sm {
        font-size: 10px;
        padding: 10px 30px;
    }
    .ads-div img{
        display: none;
    }
    .ads-div{
        padding: 12px 10px;
    }
    .ads-offer{
        padding-left: 0;
    }
    .ads-offer-inr{
        gap: 5px;
    }
    .ads-offer-span01{
        font-size: 14px;
    }
    .ads-offer-span02{
        font-size: 10px;
    }
    .ads-div .btn-theme{
        font-size: 10px;
        padding: 4px 10px;
    }
    .ads-offer-span02 {
        text-decoration-thickness: 1px;
    }
    .more-link{
        font-size: 14px;
        padding-top: 30px;
    }
}