@media(max-width: 991px){
    .left_login, .right_login {
        padding: 50px 10px;
    }
    .login_header {
        padding: 25px 20px;
    }
}
@media(max-width: 767px){
    .right_login_inner, .right_login_forget {
        width: 80%;
    }
    .heading_wrapper_div {
        flex-direction: column;
        gap: 10px;
    }
    .login_wrapper{
        flex-direction: column-reverse;
    }
    .right_login,.left_login{
        width: 100%;
    }
    .login_header{
        font-size: 20px;
    }
    .right_loginhead {
        font-size: 20px;
        margin-bottom: 10px;
    }
    .input_form_btngroup{
        width: 100%;
    }
    .input_form{
        width: calc(50% - 10px);
    }
    
}