/*********************************login******************************************************/
.left_login{
    width: 50%;
    background-color:#000029;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
}
.right_login{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 50%;
}
.login_wrapper{
    display: flex;
    width: 100%;
    background: #fff;
    height: 100%;
    flex-direction: row;
    overflow: auto;
}
.login_logo{
    width: 140px;
}
.img_logo{
    max-width: 100%;
    display: block;
    margin: auto;
}
.login_header{
    margin-bottom: 0;
    text-align: center;
    padding: 50px;
    font-family: "OpenSans Bold";
}
.form_login{
    width: 100%;
    padding: 8px 20px 8px 10px;
    font-size: 16px;
    font-weight: 400;
    background: 0 0;
    border: 0;
    border-bottom: 2px solid #ccc;
    outline: 0;
    border-radius: 0;
    margin-bottom:20px;
}
.login_forget{
    color: #007bff;
}
.login_btn{
    width: 100%;
    padding: 10px 20px;
    border-radius: 5px;
    border: 0;
    outline: 0;
    box-shadow: none;
    background-color: #4057e3;
    color:#fff;
    margin-bottom: 20px;
}
.right_loginhead{
    font-size: 25px;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-family: "OpenSans Medium";
}
.right_login_inner,.right_login_forget{
    width: 350px;
    text-align: center;
}
.form_div{
    position: relative;
}
.backto_login{
    text-align: right;
}
.backto_loginspan{
    text-decoration: underline;
    cursor: pointer;
    color: #007bff;
}
.inputError{
    color: red;
}
/*********************************login******************************************************/

/*********************************dashboard**************************************************/
.dashboard_header{
    display: flex;
    align-items: center;
}
.dashboard_logo{
    width: 140px;
}
.dashbaord_logoimg{
    max-width: 100%;
}
.dashboard_headerlist{
    margin-left: auto;
}
.dashboard_list{
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding:8px 0px;
}
.dashboard_listsvg{
    font-size: 35px;
    color: var(--theme-color);
}
.dashboard_subul{
    position: absolute;
    top: 100%;
    background: var(--theme-color);
    width: 100%;
    min-width: 120px;
    right: 0;
    display: none;
}
.dashboard_list:hover .dashboard_subul{
    display: block;
    z-index: 999;
}
.dashboard_admintext{
    font-family: "OpenSans Bold";
    margin-right: 20px;
    color: var(--theme-color);
}
.submenu_litag{
    color: #fff;
    display: block;
    width: 100%;
    padding: 8px 10px;
}
.submenu_litag:hover{
    color: #fff;
    text-decoration: none;
}
.main_header{
    background-color: #fff;
    box-shadow: 0px 0px 6px 0px #a99f9f;
}
.dashboard_listwrapper .nav_ul{
    box-shadow: 0px 5px 10px 0px #c9c0c0;
    padding: 50px;
    align-items: center;
    justify-content: center;
}
.dahsbaord_listitem{
    border: 2px solid var(--theme-color);
    margin-right: 10px;
    padding: 8px 20px;
    border-radius: 5px;
    color: var(--theme-color);
    font-family: "LibreFranklin-Medium";
    min-width: 150px;
    display: block;
    text-align: center;
    text-decoration: none;
    margin-bottom: 10px;
}
.dahsbaord_listitem:hover{
    background:var(--theme-color);
    color: #fff;
    text-decoration: none;
}
.dashboard_wrapper{
    display: flex;
    height: 100%;
    flex-direction: column;
    background: #f7f7f7;
    overflow: auto;
}
.dashboard_listwrapper{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}

/*********************************Breadcrumkb**************************************************/
.breadcrumb{
    background-color: transparent;
    margin-bottom: 0;
}
[aria-label="breadcrumb"]{
    background-color: transparent;
}
.breadcrumb-item+.breadcrumb-item::before {
    content: "";
    background-image: url('../images/downarrow.png');
    background-repeat: no-repeat;
    background-size: 15px;
    width: 14px;
    height: 14px;
    transform: rotate(270deg);
    margin-top: 4px;
    margin-right: 10px;
}
/*********************************Breadcrumkb**************************************************/

/*********************************View Report**************************************************/
.view_reportsheading{
    font-size: 25px;
    font-family: "OpenSans Regular";
    text-align: left;
    margin-bottom: 0px;
}
.text-trnfrmReport{
    text-transform: uppercase;
}
.view_report_form{
    width: 100%;
    display: block;
    padding: 6px 10px;
    font-weight: 400;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    outline: 0;
    box-shadow: none;
    position: relative;
}
.view_reports_form{
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 40px;
}
.input_form{
    width: calc(16.666667% - 10px);
    position: relative;
}
.date_imgechange{
    position: absolute;
    right: 10px;
    width: 20px;
    top: 50%;
    transform: translateY(-50%);
    filter: grayscale(1);
}
.input_form_btngroup{
    width: 33.33333%;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}
.input_form_bttn{
    background-color: var(--theme-color);
    padding: 6px 10px;
    border: 0;
    border-radius: 5px;
    color: #fff;
}
.input_form_bttn:hover{
    text-decoration: none;
    color: #fff;
}
.heading_wrapper_div{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}
/*********************************View Report**************************************************/

/*********************************pagination**************************************************/
.react_pagination{
    display: flex;
    align-items: center;
}
.pagination {
    margin-left: auto;
    flex-wrap: wrap;
}
.pagination a {
    color: black;
    float: left;
    padding: 4px 12px;
    text-decoration: none;
}
.pagination li.active {
    background-color: #645b5b;
    border-radius: 5px;
}
.pagination li.active a {
    color: white;
}
/*********************************pagination**************************************************/

/*********************************Reports**************************************************/
.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 100%;
}
.report_table_div{
    font-family: "OpenSans Regular";
    font-size: 14px;
}
.report_table_div th{
    white-space: nowrap;
}
.table_select_check{
    display: flex;
    flex-direction: column;
}
.table_select_checkbtn{
    border: 2px solid red;
    padding: 2px 20px;
    border-radius: 5px;
    font-size: 14px;
    color: red;
    background: transparent;
}
.input-section {
    width: 100%;
    height: 33px;
    border: 1px solid #d1d1d1;
    padding: 10px;
    border-radius: 5px;
}
.text-decoration-none{
    text-decoration: none;
}
.hr_line{
    border:1px solid rgba(0, 0, 0, .1);
}
/*********************************Reports**************************************************/