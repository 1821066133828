
:root {
  --theme-color: #04005A;
  --white: #fff; 
  --theme-secondary: #4057E3;
  --bg-grey:#e6e6e6;
  --colorRed: #E60C56;
  --colorGray: #E7E7E7;
  --bgblue:#000029;
}
@font-face {
  font-family: "OpenSans Light";
  src: url("../fonts/OpenSans-Light.ttf");
}
@font-face {
  font-family: "OpenSans Regular";
  src: url("../fonts/OpenSans-Regular.ttf");
}
@font-face {
  font-family: "OpenSans Medium";
  src: url("../fonts/OpenSans-Medium.ttf");
}
@font-face {
  font-family: "OpenSans SemiBold";
  src: url("../fonts/OpenSans-SemiBold.ttf");
}
@font-face {
  font-family: "OpenSans Bold";
  src: url("../fonts/OpenSans-Bold.ttf");
}
@font-face {
  font-family: "OpenSans ExtraBold";
  src: url("../fonts/OpenSans-ExtraBold.ttf");
}
html,body,#root{
  height: 100%;
}
body{
  font-family: "OpenSans Regular";
  font-size: 16px;
  color: var(--theme-color);
  background-color: var(--white);
  line-height: 1.3;
}
.wrapper{
  position: relative;
}
.header{
  padding: 15px 0;
}
/* ****************************************banner************************* */
.banner-heading,.banner-two-heading{
  font-size: 50px;
  font-family: "OpenSans Bold";
  margin-bottom: 30px;
}
.banner{
  padding-top: 75px;
}
.banner-para,.banner-two-para{
  font-size: 28px;
  font-family: "OpenSans SemiBold";
  margin-bottom: 0;
}
.banner-list,.banner-two-list {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 42px;
  margin-bottom: 42px;
  border: 0.5px solid;
  border-image-source: linear-gradient(90deg, #FFFFFF 0%, #04005A 50%, #FFFFFF 100%);
  border-image-slice: 1;
  padding: 20px 0;
  width: fit-content;
}
.banner-two-list{
  border-image-source: none;
  border-image-slice: unset;
  border: 0;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.banner-two-list-item{
  border: 1px solid var(--theme-secondary);
  background-color: #e5f0ff;
  border-radius: 8px;
  padding: 11px 16px 11px 16px;
}
.banner-list-item,.banner-two-list-item {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-family: 'OpenSans SemiBold';
}
.btn-theme{
  cursor: pointer;
    font-size: 22px;
    background-color: var(--theme-secondary);
    color: var(--white);
    padding: 20px 32px 20px 32px;
    width: 100%;
    border-radius: 60px;
    border: 0;
    font-family: 'OpenSans SemiBold';
    border: 1px solid transparent;
    transition: .5s;
    display: block;
    text-align: center;
    text-decoration: none;
}
.btn-theme:hover{
  background-color: var(--white);
  color: var(--theme-secondary);
  border: 1px solid  var(--theme-secondary);
}
.btn-sm {
  font-size: 14px;
  padding: 13px 32px;
  display: inline;
}
.banner_bar,.banner_two_bar{
  background: var(--colorGray);
border-radius: 50px;
height: 12px;
width: 100%;
margin-top: 30px;
}
.banner_progress,.banner_two_progress{
  background: var(--colorRed);
  height: 100%;
  width: 100%;
  border-radius: 50px;
}
.banner_book_div{
  margin-top: 20px;
  font-size: 15px;
  text-align: center;
  font-family: 'OpenSans Bold';
}
.banner_line{
  width: 1px;
  height: 17px;
  background: var(--theme-color);
  display: inline-block;
  margin-left: 8px;
  margin-right: 5px;
  transform: translateY(3px);
}
.banner_book{
  font-family: 'OpenSans Bold';
}
/* ****************************************banner************************* */
.career-mastery{
  /* margin-top: 105px; */
  margin-bottom: 70px;
}
.career_details {
  display: flex;
  flex-direction: column;
  gap: 83px;
  margin-top: 40px;
  position: relative;
  counter-reset: my-sec-counter 0;
}
.career_details::before{
  content: '';
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: var(--theme-color);
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.career_inner{
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16%;
}
.career_inner:last-child::after{
  content: '';
  width: 2px;
  height: 100%;
  background: #fff;
  top: 0;
  position: absolute;
  right: 50%;
  transform: translateX(-50%);
  margin-right: -2px;
}
.career_inner::before{
  counter-increment: my-sec-counter;
  content: "0"counter(my-sec-counter);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 70px;
  height: 70px;
  background: var(--theme-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-family: 'OpenSans SemiBold';
  font-size: 20px;
  z-index: 1;
}
.career_inner_left,.career_inner_right{
  width: 42%;
  box-shadow: 0px 4px 32px 0px #04005A1A;
  padding: 24px;
  border-radius: 20px;
}
.career_head{
  font-size: 22px;
  font-family: 'OpenSans Bold';
  margin-bottom: 30px;
}
.main-para{
  font-size: 18px;
  font-family: 'OpenSans SemiBold';
  margin-bottom: 0;
}
.corporate-wrap {
  margin-top: 56px;
}
.career-btn-div{
  width: 320px;
  margin-top: 25px;
}
.team-member-div{
  margin-top: 55px;
}
.team-member-div .main-head{
  padding-bottom: 87px;
}
.client-org-wrap{
  padding-top: 9px;
}
.featured-wrap.about-wrap {
  margin-top: 15px;
  padding-bottom: 50px;
}
.banner-two{
  padding-top: 80px ;
  text-align: center;
}
.banner_two_connt{
  width: 410px;
  margin: auto;
  margin-top: 46px;
}
.possible-div{
  border: 2px solid #EEF5FF;
  box-shadow: 0px 4px 32px 0px #04005A1A;
  padding: 24px;
  border-radius: 16px;
  margin-bottom: 20px;
}
.possible-div:last-child{
  margin-bottom: 0;
}
.head-last{
  font-size: 22px;
    font-family: 'OpenSans Bold';
    display: flex;
    align-items: center;
    gap: 25px;
    margin-bottom: 15px;
}
.possible-span{
    width: 46px;
    height: 46px;
    display: inline-block;
    background: #EEF5FF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}
.possible-div .main-para{
  font-size: 19px;
}
.home-ads{
  background-color: var(--theme-color);
  position: sticky;
  bottom: 0;
  z-index: 9;
}
.ads-div{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.ads-div .btn-theme{
  width: 300px;
  margin-left: auto;
  margin-right: 15px;
  background-color: var(--colorRed);
    text-align: center;
    text-decoration: none;
}
.ads-div .btn-theme:hover{
  background-color: transparent;
}
.ads-offer-inr{
  color: var(--white);
  display: flex;
  align-items: center;
  gap: 15px;
}
.ads-offer-span01{
  font-family: 'OpenSans Bold';
  font-size: 28px;
}
.ads-offer-span02{
  font-size: 18px;
  font-family: 'OpenSans Bold';
  position: relative;
  text-decoration-line: line-through;
  text-decoration-thickness: 3px;
}
/* .ads-offer-span02::after{
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: var(--white);
} */
.ads-offer-span03{
  border: 1px solid #F8CE08;
  border-radius: 60px;
  padding: 8px 14px 8px 14px;
  font-size: 12px;
  color: #F8CE08;
}
.ads-offer{
  padding-left: 50px;
  padding-right: 50px;
}
.ads-offer-bottom{
  color: var(--white);
  font-size: 14px;
  margin-top: 10px;
  font-family: 'OpenSans SemiBold';
}








































































































































































































































































































































































/* *************************Greeshma********************************* */
.about-wrap{
  margin-top: 105px;
}
.main-head{
  font-size: 44px;
  font-family: "OpenSans Bold";
  text-align: center;
  line-height: 61.37px;
  padding: 76px 0px;
  padding-bottom: 70px;
}
.main-head-half {
  width: 70%;
  margin: 0 auto;
}
.img-feature{
  display: flex;
  justify-content: center;
}
.about-features{
  display: flex;
  gap: 120px;
  justify-content: center;
  padding-bottom: 50px;
  flex-wrap: wrap;
}
.about-features .features {
  width: 180px;
}
.desc-feature{
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 20px;
}
.feature-digit-large{
font-size: 35px;
font-family: "OpenSans SemiBold";
}
.feature-digit-small{
  font-size: 20px;
  font-family: "OpenSans SemiBold";
}
.abt-feauture-img{
  width:130px;
  height:130px;
}
/* --------------------------------------------- */
.parnters{
display: flex;
gap:18px;
flex-wrap: wrap;
justify-content: center;
}
.parnters-featured{
  display: flex;
  gap:6rem;
  flex-wrap: wrap;
  justify-content: center;
  }
  .parnters-featured .partner-img{
    opacity: 0.6;
  }
.partner-img {
  width: 100%;
  height: 70px;
  /* width: auto; */
  object-fit: contain;
}
.parnterimg {
  padding-bottom: 20px;
  width: 15%;
}
.more-link
{
  text-align: center;
  font-size: 22px;
  font-family: "OpenSans Bold";
  color: #4057E3;
  padding-top: 54px;
}
/* ------------------------------------------ */
.career-tools-wrapper {
  margin-top: 105px;
}
.career-tools{
  display: flex;
  gap:20px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
  align-items: stretch;
}
.tools{
  width:350px;
  box-shadow: 0px 4px 32px 0px #04005A1A;
    padding: 20px;
    border-radius: 20px;
}
.toolimage,.toolname,.tooldesc{
  display: flex;
  align-items: center;
  text-align: center;
justify-content: center;
}
.toolname{
  font-size: 20.5px;
  font-family: "OpenSans Bold";
  padding: 20px 0px;
}
.tooldesc{
  font-size: 18px;
  font-family: "OpenSans SemiBold";
  padding-bottom: 10px;
}
.client-says-wrapper {
  margin-top: 105px;
}
.clientsay{
  background-color: #EEF5FF;
  padding: 20px;
  margin-bottom: 2rem;
  border-radius: 10px;
}
.clientdata{
  display: flex;
  gap:20px;
}
/* -------------------------------------- */
.client-org-wrap,.career-wrap{
  background: #EEF5FF;
}
.features-wraper {
  margin-top: 10px;
}
.featured-div{
  display: flex;
  gap:30px;
  justify-content: center;
}
.features-sub{
  width:235px;
}
.features-sub .desc-feature{
  padding: 20px 0px;
}
.career-advisor-desc{
  font-size: 22px;
  line-height: 34px;
}
.text-sm{
  font-size: 14px;
}
.text-bold{
  font-family: "OpenSans Bold";
}
.star-border{
  border: 0.5px solid;
  border-image-source: linear-gradient(90deg, #eef5ff 0%, #5650d2 50%, #eef5ff 100%);
  border-image-slice: 1;
  border-bottom: none;
  padding: 15px 0px;
}
/* ----------------- Team Member --------------------- */
.t-m-list-4 {
  height: 350px;
  margin-top: 0;
  margin-bottom: 1.5rem;
}
.t-m-list-6 {
  height: 280px;
  margin-top: 0;
  margin-bottom: 1.5rem;
}
.t-m-list-sub {
  background-color: #EEF5FF;
  padding: 20px;
  border-radius: 20px;
  height: 100%;
}
.t-m-list-name {
  width: 70%;
}
.t-m-profile {
  border-radius: 50%;
  margin-right: 15px;
}
.t-m-heading {
  font-size: 22px;
  font-family: "OpenSans SemiBold";
  color: var(---theme-color);
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 95%;
  display: inline-block;
  text-transform: capitalize;
}
.t-m-list-post {
  font-size: 18px;
}
.t-m-list-content{
  font-size: 18px;
  line-height: 24px;
}
/* ----------------- Team Member --------------------- */

/* ----------------- Career Coach --------------------- */
.c-coach-img-title {
  font-size: 32px;
  font-family: "OpenSans Bold";
  text-align: center;
  margin-top: 20px;
  font-style: italic;
}
/* ----------------- Career Coach --------------------- */

/* ----------------- FAQ --------------------- */
.faq-wraper {
  margin-top: 105px;
  margin-bottom: 100px;
}
.faq-wraper .accordion-button::after {
  display: none;
}
.faq-wraper .accordion-button::before {
  background-image: url("../images/minus.png");
  content: "";
  width: 30px;
  height: 30px;
  background-size: contain;
  left: 15px;
  position: absolute;
}
.faq-wraper .accordion-button.collapsed::before {
  background-image: url("../images/add.png");
}
.faq-wraper .accordion-button {
  font-family: "OpenSans Bold";
  font-size: 20px;
  color: var(--theme-color);
  padding: 2rem 1.25rem;
  padding-left: 70px;
}
.faq-wraper .accordion-body {
  color: var(--theme-color);
  font-family: "OpenSans SemiBold";
  font-size: 18px;
  line-height: 26px;
}
.faq-wraper .accordion-item {
  border: 0;
  box-shadow: 0px 4px 32px 0px #04005A1A;
  margin-bottom: 20px;
  border-radius: 12px;
  overflow: hidden;
}
.faq-wraper .accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: unset
}
/* ----------------- FAQ --------------------- */

.footer{
  background-color: #EEF5FF;
  font-size: 14px;
  font-family: "OpenSans SemiBold";
}
.footer .nav-link {
  color: var(--theme-color);
  font-family: "OpenSans SemiBold";
  padding: 7px;
  position: relative;
}
.copy-right {
  font-family: "OpenSans SemiBold";
}
.footer-hr {
  border-top: 1px solid #a09dd1;
  opacity: 1;
}
.footer-links .nav-link {
  /* padding: 7px 20px; */
  padding: 7px;
}
.footer-links .nav-link:nth-child(1), .footer-links .nav-link:nth-last-child(1) {
  padding: 7px;
}
/* .footer-links .nav-link:after {
  position: absolute;
  content: " | ";
  right: -3px;
} */
.footer .nav-link:nth-last-child(1):after {
  display: none;
}

/* Register */
.register-wrap{
  height: 100%;
}
.register-left{
  background-color: var(--bgblue);
  height: 100%;
  padding: 68px 60px 0px 60px;
}
.reg-head{
  font-size: 38px;
  font-family: "OpenSans SemiBold";
  line-height: 54px;
  color:var(--white);
  padding-top: 100px;
}
.registration-info{
  display: flex;
  gap:40px;
  padding-top: 60px;
  
  padding-bottom: 100px;
}
.reg-data{
  display: flex;
  gap:10px;
  color:var(--white);
  align-items: center;
}
.reg-ico{
  width:24px;
  height: 23px;
  object-fit: contain;
}
.mentoredBy{
  padding-top: 25px;
  color:#fff;
}
.mentor-image{
  width:60px;
  height: 60px;
  object-fit: contain;
  border-radius: 8px;
}
.mentor-data{
  display: flex;
  gap:11px;
  padding-top: 10px;
  padding-bottom: 150px;
  align-items: center;
}
.mentor-name{
  font-size: 22px;
  font-family: "OpenSans SemiBold";
  padding-bottom: 5px;
}
.bymentor{
  font-size: 17.5px;
  padding-bottom: 5px;
}
.mentor-position{
  font-size: 13px;
  font-family: "OpenSans SemiBold";
}
.register-right{
  height: 100%;
  padding: 68px 60px 0px 60px;
}
.back-to{
  width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.reg-form{
  padding-top: 100px;
}
.reg-form .form-control{
  height: 70px;
  border-radius: 12px;
  border:1px solid var(--theme-color);
  font-family: "OpenSans SemiBold";
  font-size: 24px;
  color:var(--theme-color);
}
.reg-form select{
  font-family: "OpenSans SemiBold";
  font-size: 24px;
  color:var(--theme-color)
}
.form-label{
  font-size: 16.5px;
  font-family: "OpenSans SemiBold";
  line-height: 24px;

}
.reg-helptext{
  font-size: 14.5px;
  line-height: 21px;
  color: var(--theme-color);
  text-align: center;
  padding: 10px 0px;
  font-family: "OpenSans Medium";
}
.input-group-text{
  border:1px solid var(--theme-color);
  border-radius: 12px;
}
.reg-form .form-control.border-left-0{
  border-left:0px;
  outline: none;
  box-shadow: none;
}
.reg-form .border-none{
  border:none;
  box-shadow: none;
  outline: none;
}
.seperator{
  background: #686778;
    width: 2px;
    height: 26px;
}

.form-select:focus{
  box-shadow: none;
  outline: none;
}
.form-space{
  margin-bottom: 2rem;
}
.reg-links{
  color: var(--theme-color);
  word-break: break-all;
    display: inline-block;
}
.register-left {
  min-height: 100vh;
}
.text-small{
  font-size: 13px;
  padding-top: 5px;
}
.thankyou-wrap{
  height: 100%;
}
.thankyou-body{
  height: calc(100vh - 100px);
  align-items: center;
  display: flex;
}
.thankyou{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  height: 100vh;
  border-top-left-radius: 450px;
    border-top-right-radius: 450px;
    border: 3px solid #D5E7FF;
    border-bottom: 0;
}
.thank-head{
  font-family: "OpenSans Bold";
  font-size: 36px;
  color: var(--bgblue);
  margin-bottom: 10px;
}
.thank-desc{
  font-size: 18px;
  color: var(--bgblue);
  font-family: "OpenSans SemiBold";
  text-align: center;
  padding: 15px 25px;
}
.thank-you-footer{
  background-image: url("../images/footer-thankyou.png");
  background-color: transparent;
  background-size: contain;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* position: absolute; */
  /* bottom: 0; */
  width: 100%;
}
.thank-img{
  padding-bottom: 2rem;
}


.loader-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  top: 0;
  bottom: 0;
  text-align: center;
  display: none;
  align-items: center;
  justify-content: center;
}
.loader {
  display: flex;
  column-gap: 10px;
}

.dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #008ae6;
  box-shadow: 0 5px 10px rgba(255, 255, 255, 0.01);
  animation: animate 1s ease-in-out infinite alternate;
}

.dot:nth-child(1){
  animation-delay: -0.25s;
}

.dot:nth-child(2){
  background: #e60000;
  animation-delay: -0.5s;
}

.dot:nth-child(3){
  background: #ffcc00;
  animation-delay: -0.75s;
}

.dot:nth-child(4){
  background: #008800;
  animation-delay: -1s;
}

@keyframes animate {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(5px);
  }
}
.star-wrap{
  display: flex;
  align-items: center;
  width:100%;
}
.star-wrap img{
 object-fit: contain;
}
.video-container{
  position: relative;
}
.video-container video{
  border-radius: 20px;
  height: 100%;
  width:100%;
}
.videoIcon{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0;
  cursor: pointer;
}


/* Landing3 */

.bg-blue{
  background-color:var(--bgblue) ;
}

.abtwrap3-mainhead{
  color:var(--theme-color);
  font-size: 30px;
  font-family: 'OpenSans Bold';  
 padding: 40px 30px 0px 0px;
}
.abtwrap3-tophead{
 color:#666666;
 font-size: 20px;
 font-family: 'OpenSans Semibold';
 text-transform: uppercase;
}
.abtwrap3-head3{
  text-align: center;
  text-transform: uppercase;
  font-family: 'OpenSans Semibold';
  font-size: 20px;  
 color:#666666;
 padding-bottom: 6rem;
}
.client-bottom-bar{
  padding: 20px;
  border-radius: 20px;
}
.client-bottom-bar-p{
  font-size: 22px;
  color:#fff;
  font-family: 'OpenSans Semibold';
}
.online-label{
  background-color: #60B51E;
  color: #fff;
  font-family: 'OpenSans Semibold';
  display: inline-block;
  padding: 2px 10px;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 13px;
}
.main-head-3{
  font-size:30px;
  font-family: "OpenSans Bold";
  text-align: center;
  padding: 0px 80px;
}
.featured-text{
  color:var(--theme-color);
  font-family: 'OpenSans Semibold';
}
.card-featured{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 20px;
}
.card-img img{
  margin: 35px 0px 0px 0px;
}
.main-features{
  background-color: #fff;
  padding: 20px;
  margin-bottom: 5rem;
  min-height: 200px;
  border-radius: 20px;
  box-shadow: rgb(223 224 226) 0px 7px 29px 0px;
}
.main-features .t-m-heading{
  font-size: 18.5px;
  white-space: initial;
  font-family: "OpenSans Bold";
}
.main-head3{
  padding: auto 70px;
  width:500px;
  margin:auto;
}
.aboutwrap-3{
  box-shadow: rgb(223 224 226) 0px 7px 29px 0px;
  border:none;
  padding: 30px;
  border-radius: 20px;
  margin-bottom: 80px;
}
.abt-ex-tools{
  position: relative;
}
.ex-tools{
  padding: 10px 33px;
    border: 1px solid #ddd;
    color: var(--theme-color);
    font-size: 17px;
    font-family: "OpenSans Bold";
    text-align: center;
    padding-top: 75px;
    width: 200px;
    min-height: 150px;
    border-radius: 20px;
}
.ex-img{
  position: absolute;
  right:50%;
  left:50%;
  transform:translate(-50%,-50%);
  width:60%;
}
.about-head{
 width: 700px;
 margin: auto;
}
.main-head-sub{
  font-size: 23px;
}
body .slick-prev:before,body .slick-next:before{
  color:#000;
}
body .slick-prev{
  top:105%;
  left:40%;
  z-index:9999;
}
body .slick-next{
  top:105%;
  right:40%;
  z-index:9999;
}
.slider .video-container{
  margin:7px;
  /* min-height: 250px; */
}
.slider .video-container video{
object-fit: fill;
}
.slider .slick-dots li button:before {
  font-family: 'slick';
  font-size: 12px;
}
.slider .slick-dots li.slick-active button:before{
  color:#E60C56;
}
.slider .slick-prev:before{
content:url("../images/left.png")
}
.slider .slick-next:before{
  content:url("../images/right.png")
  }
.slider .slick-list{
  /* padding-bottom: 50px ; */
  /* height: 350px; */
}
/* .slick-slider .slick-slide.slick-current{
  padding: 30px 0px;
} */

.slick-slider .slick-slide {
  float: none;
  display: inline-block !important;
  vertical-align: middle;
  padding: 10px 0px;
  background-color: white;
  transition: all 0.3s ease;
  height: auto;
  text-align: center;
}
/* .slick-slider .slick-slide.slick-current .video-container{
height: 260px;
} */
.inr-cut{
  text-decoration: line-through;
    color:#E60C56 ;
    /* display: block; */
}
.toolname{
  display: block;
  /* width:100%; */
  width: 75%;
    margin: 0 auto;
}
.slider .slick-dots{
  bottom: -32px;
}
.career-right-img{
  height: 100%;
}
.video-player {
  transform: scaleY(1);
  /* height:250px; */
    transition: .5s;
}

.video-player.playing {
  transform: scaleY(1.2);
  /* height:280px; */
}
.text-bold{
  font-family: "OpenSans ExtraBold";
}
.toolimage{
  min-height: 180px;
}
